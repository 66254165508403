import { useEffect, useState, useRef } from "react";
import React from 'react';

import { useSelector } from "react-redux";
import axios from "axios";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { Spinner } from "react-bootstrap";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { MultiAnswerCodeView, SingleAnswerCodeView, TableAnswerCodeView, LibraryViewContainer, SidePanel, AccordionCard, AccordionTitleContainer, AccordionTitle, AccordionIcon, AccordionSubSection, AccordionSubTitle, HeaderContainer, HeaderText, SearchInput, LibraryContainerBody, DetailsSection, PageSection, PageHeader, PageContent, PageFooter, PageFooterNavigation, MultiUserSelect, SingleUserSelect } from "../../components";
import CloseGrayIcon from '../../assets/images/glyph-disclosure-open-dark-gray.svg';
import OpenGreenIcon from '../../assets/images/glyph-disclosure-close-green.svg';
import { NoMatch } from "../NoMatch";

const LibraryView = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    const [searchValue, setSearchValue] = useState('');
    const [pageContainLoading, setPageContainLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [allPages, setAllPages] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [openSections, setOpenSections] = useState([]);
    const [activePage, setActivePage] = useState({});
    const [libraryData, setLibraryData] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const accordionRefs = useRef({});

    useEffect(() => {
        getUsersList();
        getLibData();
        getSectionData();
    }, []);

    const selectPage = (page_id, pages) => {
        const section = document.querySelector('.sub-section');
        if (section) {
            section.scrollTo({ top: 0, behavior: 'smooth' });
        }
        const url = new URL(window.location.href);
        url.searchParams.set('page', page_id);
        window.history.replaceState({}, '', url);
        const page = pages.find(p => p.id === page_id);
        if (!page) {
            setActivePage({});
            return;
        };
        setActivePage(page);
        getPageData(page);
    };

    const getUsersList = async () => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/master/users_by_location/${currentUser.location._id}`);
            setUsersList(payload);
        } catch (error) {
            console.error("🚀 ~ file: LibraryView.jsx:39 ~ getUsersList ~ error:", error);
        }
    };

    const getSectionData = async (params = '') => {
        try {
            setListLoading(true);
            setSearchValue(params);

            // Construct URL
            let url = `/api/v2/libraryPages/${currentUser.location._id}`;
            if (params) url += `?searchValue=${params}`;

            const { data: { payload } } = await axios.get(url);

            // If the payload doesn't contain valid data, reset state
            if (!(payload.includes && payload.items)) {
                resetSectionData();
                return;
            };

            // Process sections and pages
            const { sectionList, openSections, pages } = processPayload(payload, params);

            // Update state with the processed data
            setSectionList(sectionList);
            setAllPages(pages);
            if (params) {
                setOpenSections(openSections);
                const url = new URL(window.location.href);
                url.searchParams.set('page', '');
                window.history.replaceState({}, '', url);
            };

            // Automatically select the first page if no specific page is requested
            selectInitialPage(sectionList, pages);

            setListLoading(false);
        } catch (error) {
            console.error("🚀 ~ file: LibraryView.jsx:133 ~ getSectionData ~ error:", error);
            setListLoading(false); // Ensure loading state is disabled on error
        }
    };

    /**
     * Process payload to extract sections, pages, and open sections
     */
    const processPayload = (payload, params) => {
        const tempSectionList = [];
        const pages = [];
        const tempOpenSections = [];

        // Extract sections and their sort orders
        payload.includes.Entry.forEach(ent => {
            tempSectionList.push({
                index: ent.fields.sortOrder || 0,
                title: ent.fields.title,
                id: ent.sys.id,
                pages: []
            });
        });

        // Associate pages with their respective sections
        payload.items.forEach(item => {
            const parentId = item.fields.parent?.sys.id;
            const section = tempSectionList.find(section => section.id === parentId);
            if (section) {
                section.pages.push({
                    title: item.fields.title,
                    id: item.sys.id,
                    sectionId: section.id,
                    index: item.fields.sortOrder
                });
            };
        });

        // Sort sections and their pages by sortOrder
        const sortedSections = tempSectionList
            .sort((a, b) => a.index - b.index)
            .map(section => {
                section.pages = section.pages.sort((a, b) => a.index - b.index);
                return section;
            });

        // Handle open sections when search params exist
        if (params) {
            sortedSections.forEach(section => {
                if (section.pages.length) tempOpenSections.push(section.id);
            });
        };

        // Create a flattened list of pages for easier pagination
        let page_number = 0;
        sortedSections.forEach(section => {
            section.pages.forEach(item => {
                pages.push({
                    title: item.title,
                    id: item.id,
                    pageNumber: page_number,
                    sectionId: section.id,
                    index: item.index
                });
                page_number++;
            });
        });

        return { sectionList: sortedSections, openSections: tempOpenSections, pages };
    };

    /**
     * Reset section-related state in case of empty or invalid payload
     */
    const resetSectionData = () => {
        setSectionList([]);
        setAllPages([]);
        setOpenSections([]);
        setActivePage('');
        const url = new URL(window.location.href);
        url.searchParams.set('page', '');
        window.history.replaceState({}, '', url);
    };

    /**
     * Automatically select a page after loading sections
     */
    const selectInitialPage = (sections, pages) => {
        if (sections.length && sections[0].pages.length) {
            const searchParams = new URLSearchParams(window.location.search);
            const selectedPage = searchParams.get('page') || sections[0].pages[0].id;
            selectPage(selectedPage, pages);
        };
    };

    const getLibData = async () => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/libraryData/${currentUser.location._id}`);
            setLibraryData(payload.libraryData);
        } catch (error) {
            console.error('error getting library data', error);
            return false;
        }
    };

    const getPageData = async (page) => {
        try {
            setPageContainLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/libraryPage`, { params: { pageId: page.id } });
            setActivePage({ ...page, ...payload.fields, id: payload.sys.id });
            setOpenSections([page.sectionId]);
            setPageContainLoading(false);
        } catch (error) {
            setPageContainLoading(false);
            console.error("🚀 ~ file: LibraryView.jsx:180 ~ ~ error:", error);
        }
    };

    const openCloseSections = (sectionId) => {
        if (openSections.includes(sectionId)) {
            const tempSections = openSections.filter(id => id !== sectionId);
            setOpenSections(tempSections);
        } else {
            setOpenSections([...openSections, sectionId]);

            if (accordionRefs.current[sectionId]) {
                accordionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
            }
        };
    };

    const getCodeValue = (code) => {
        const associatedVariable = typeof code === 'string' ? code.replace(/`/g, '').replace(/ /g, '') : code[0].replace(/`/g, '').replace(/ /g, '');

        if (associatedVariable === 'PRACTICE_NAME') {
            return {
                associatedVariable,
                answer: currentUser.practice.name,
                inputType: 'singleInput',
                stepNumber: 99,
                question: 'Practice Name',
                editOff: true
            };
        } else if (associatedVariable === 'LOCATION_NAME') return {
            associatedVariable,
            answer: currentUser.location?.name,
            inputType: 'singleInput',
            stepNumber: 99,
            question: 'Location Name',
            editOff: true
        };

        const tempResult = libraryData.find(item => item.associatedVariable === associatedVariable);
        if (tempResult) return {
            associatedVariable: tempResult.associatedVariable,
            answer: tempResult.answer,
            inputType: tempResult.type,
            stepNumber: tempResult.stepNumber,
            question: tempResult.question,
            isTaskForThisStep: tempResult.isTaskForThisStep,
            taskDetails: tempResult.isTaskForThisStep ? tempResult.taskDetails : {},
            tableLayout: tempResult.tableLayout ? tempResult.tableLayout : [],
            additional: tempResult.additional || false,
            isSubmitted: true,
            deleted: false
        };

        return '';

    };

    const escapeRegExp = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

    const getOptions = () => {
        let option = {
            renderText: text => {
                if (text.match(/^`[A-Z_]+`$/)) {
                    return text;
                };
                const regex = new RegExp(`(${escapeRegExp(searchValue)})`, 'gi');
                return text.split(regex).map((chunk, i) =>
                    chunk.match(regex) ? <span key={i} style={{ backgroundColor: 'yellow' }}>{chunk}</span> : chunk
                );
            },
            renderMark: {
                [MARKS.CODE]: (text) => customCodeHandler(text)
            },
            renderNode: {
                [INLINES.HYPERLINK]: ({ data }, children) => (
                    <a
                        href={data.uri}
                        target='_blank'
                    >{children}</a>
                ),
                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    const { file, title } = node.data.target.fields;
                    return (
                        <iframe
                            src={file.url}
                            alt={title}
                            style={{ minHeight: '30rem', width: '100%' }}
                        />
                    );
                },
                [BLOCKS.TABLE]: (node) => {
                    return <div className="custom-table-class w-100">{documentToReactComponents(node)}</div>;
                }
            }
        };
        if (!searchValue) delete option.renderText;
        return option;
    };

    const customCodeHandler = (node) => {
        const val = getCodeValue(node);
        if (val && val.answer) {
            switch (val.inputType) {
                case 'singleInput':
                    return <SingleAnswerCodeView answerData={val} user={currentUser} getLibData={getLibData} usersList={usersList} />
                    break;
                case 'table':
                    return <TableAnswerCodeView answerData={val} headers={Object.keys(val.answer[0])} user={currentUser} getLibData={getLibData} usersList={usersList} />
                    break;
                case 'multipleInput':
                    return <MultiAnswerCodeView answerData={val} user={currentUser} getLibData={getLibData} usersList={usersList} />
                    break;
                case 'multipleUserSelect':
                    return <MultiUserSelect usersList={usersList} answerData={val} user={currentUser} getLibData={getLibData} />
                    break;
                case 'singleUserSelect':
                    return <SingleUserSelect usersList={usersList} answerData={val} user={currentUser} getLibData={getLibData} />
                    break;
                default:
                    break;
            };
        };
    };

    return (
        <LibraryViewContainer>
            <HeaderContainer className="mb-4 pb-2">
                <HeaderText>Library</HeaderText>
                <div className="d-flex justify-content-end">
                    <SearchInput placeholder="Search" value={searchValue} onChange={(e) => getSectionData(e.target.value)} type="text" />
                </div>
            </HeaderContainer>
            <LibraryContainerBody>
                <SidePanel className='p-0 pr-2' lg='3' md='12' sm='12'>
                    {
                        listLoading &&
                        <AccordionCard className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </AccordionCard>
                    }
                    {
                        !listLoading && sectionList.map(section => (
                            <React.Fragment key={section.id}>
                                <div key={section.id} ref={el => accordionRefs.current[section.id] = el}>

                                    <AccordionCard key={section.id}>
                                        <AccordionTitleContainer onClick={() => openCloseSections(section.id)}>
                                            <AccordionTitle>
                                                {section.title}
                                            </AccordionTitle>
                                            {
                                                openSections.includes(section.id) ?
                                                    <AccordionIcon src={OpenGreenIcon} alt='Open Icon' />
                                                    :
                                                    <AccordionIcon src={CloseGrayIcon} alt='Close Icon' />
                                            }
                                        </AccordionTitleContainer>
                                        {
                                            openSections.includes(section.id) &&
                                            <>
                                                <hr />
                                                {
                                                    section.pages.map(page =>
                                                        <AccordionSubSection onClick={() => selectPage(page.id, allPages)} key={page.id} className="mb-2">
                                                            <AccordionSubTitle active={page.id === activePage.id}>
                                                                {page.title}
                                                            </AccordionSubTitle>
                                                        </AccordionSubSection>
                                                    )}
                                            </>
                                        }
                                    </AccordionCard>
                                </div>
                            </React.Fragment>
                        ))}
                </SidePanel>

                <DetailsSection lg='9' md='12' sm='12' className="sub-section">
                    <PageSection >
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={pageContainLoading || 'initial'}
                                timeout={300}
                                classNames="fade"
                                unmountOnExit
                            >
                                {listLoading || pageContainLoading ?
                                    <div className="text-center">
                                        <Spinner animation="border" variant="success" />
                                    </div>
                                    :
                                    sectionList && sectionList.length ?
                                        <div>
                                            <PageHeader>{activePage.title}</PageHeader>
                                            <PageContent>
                                                {Object.keys(activePage).length ?
                                                    documentToReactComponents(activePage.body, getOptions()) :
                                                    <NoMatch hideLink={true} />
                                                }
                                            </PageContent>
                                            <PageFooter>
                                                <PageFooterNavigation>
                                                    {
                                                        activePage.pageNumber > 0 &&
                                                        <p onClick={() => selectPage(allPages[activePage.pageNumber - 1]?.id, allPages)}>
                                                            {'<< ' + allPages[activePage.pageNumber - 1]?.title}
                                                        </p>
                                                    }
                                                </PageFooterNavigation>
                                                <PageFooterNavigation>
                                                    {
                                                        activePage.pageNumber < allPages.length - 1 &&
                                                        <p className="text-right" onClick={() => selectPage(allPages[activePage.pageNumber + 1]?.id, allPages)}>
                                                            {allPages[activePage.pageNumber + 1]?.title + ' >>'}
                                                        </p>
                                                    }
                                                </PageFooterNavigation>
                                            </PageFooter>
                                        </div>
                                        :
                                        <NoMatch hideLink={true} msg_text='Does not match. Please try a different keyword.' />

                                }
                            </CSSTransition>
                        </SwitchTransition>
                    </PageSection>
                </DetailsSection>
            </LibraryContainerBody>
        </LibraryViewContainer>
    )
}

export default LibraryView;